<template>
  <error-content code="401" desc="您没有浏览这个页面的权限" :src="src"/>
</template>

<script>
// import error401 from '@/assets/images/error-page/error-401.svg'
import errorContent from './error-content.vue'
export default {
  name: 'error_401',
  components: {
    errorContent
  },
  data() {
    return {
      src: null // error401
    }
  }
}
</script>
